import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Python', 'Java', 'JavaScript', 'Node.js', 'SQL', 'C/x86-64 Assembly'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hi! I'm Shreyas, a computer science major at Purdue University. My passion for
              computer science was ignited when I first began learning Python and developed a
              graphics-based command-line version of hangman. One thing that stood out to me through
              this process was the idea of developing highly efficient and scalable algorithms to
              tackle real-world problems.
            </p>

            <p>
              Fast-forward to today, and I’ve had the privilege of working at{' '}
              <a target="_blank" href="https://www.pnnl.gov/" rel="noreferrer">
                Pacific Northwest National Laboratory
              </a>
              , a DOE-affiliated national lab to tackle issues related to modernizing irrigation
              systems,{' '}
              <a target="_blank" href="https://www.surgetheory.org/" rel="noreferrer">
                The Knudsen Institute
              </a>
              , a private non-profit research organization where I developed solutions to combat
              disruptions in the automotive supply chain,{' '}
              <a href="https://www.spacecowllc.com/" target="_blank" rel="noreferrer">
                Space Cow
              </a>
              , where I contributed to a platform for developers to rapidly develop chatbot
              applications, and{' '}
              <a href="https://htfpurdue.org/" target="_blank" rel="noreferrer">
                Hack The Future
              </a>
              , where I'm working towards building an IVR-based system for SFSL Food Pantry to
              efficiently connect with and deliver food to those in need.
            </p>

            <p>
              My ultimate goal is to not only contribute to the field as a computer vision engineer,
              specifically in developing advanced algorithms for autonomous vehicles, but also
              improve my ability to tackle algorithmic problems in a systematic manner.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
